<template>
  <div>
    <!-- <button @click="dangerNotify">sd as</button> -->
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "TheAlerts",
  data() {
    return {};
  },
  computed: {
    ...mapState({
      checkAlert: "checkAlert",
      successM: "successMessage",
      warningM: "warningMessage",
      infoM: "infoMessage",
      dangerM: "dangerMessage",
    }),
  },
  watch: {
    checkAlert() {
      if (this.checkAlert === 1) {
        this.successNotify();
        setTimeout(() => {
          this.$store.commit("expireAlert");
        }, 1000);
      } else if (this.checkAlert === 2) {
        this.dangerNotify();
        setTimeout(() => {
          this.$store.commit("expireAlert");
        }, 1000);
      } else if (this.checkAlert === 3) {
        this.warningNotify();
        setTimeout(() => {
          this.$store.commit("expireAlert");
        }, 1000);
      } else if (this.checkAlert === 4) {
        this.infoNotify();
        setTimeout(() => {
          this.$store.commit("expireAlert");
        }, 1000);
      }
    },
  },
  methods: {
    successNotify() {
      this.$toast.success(this.successM, {
        position: "bottom-left",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: true,
      });
    },
    dangerNotify() {
      this.$toast.error(this.dangerM, {
        position: "bottom-left",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: true,
      });
    },
    warningNotify() {
      this.$toast.warning(this.warningM, {
        position: "bottom-left",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: true,
      });
    },
    infoNotify() {
      this.$toast.info(this.infoM, {
        position: "bottom-left",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: true,
      });
    },
  },
  destroyed() {
    this.$store.commit("expireAlert");
  },
};
</script>
