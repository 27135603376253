export default {

  jobs: [],
  employerJobs: {},
  blogs: [],
  blog: {},
  events: [],
  event: {},
  quizList: [],
  quizDetails: {},
  quizExcel: null,


};
