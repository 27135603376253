export default {

  jobCategories: (state) => {
    return state.jobCategories;
  },

  jobCategoryOptions: (state) => {
    return state.jobCategoryOptions;
  },
  blogCategoryOptions: (state) => {
    return state.blogCategoryOptions;
  },

  packages: (state) => {
    return state.packages;
  },
  package: (state) => {
    return state.package;
  },

  banners: (state) => {
    return state.banners;
  },

  ads: (state) => {
    return state.ads;
  },

  dashboard: (state) => {
    return state.dashboard;
  },

  dashboardChart: (state) => {
    return state.dashboardChart;
  },

  pageContents: (state) => {
    return state.pageContents;
  },

  pageContent: (state) => {
    return state.pageContent;
  },

  faqList: (state) => {
    return state.faqList;
  },

  teamList: (state) => {
    return state.teamList;
  },

  companies: (state) => {
    return state.companies;
  },

  countries: (state) => {
    return state.countries;
  },

  countryOptions: (state) => {
    let countryOptions= [];
    state.countries.forEach((item) => {
      const optionItem = {
        value: item.country,
        text: item.country,
        id: item._id,
      };
      countryOptions.push(optionItem);
    });
    return countryOptions;
  },

  country: (state) => {
    return state.country;
  },


  categoryTypes() {
    return [
      {value: "job", text: "دسته‌بندی فرصت های کارآموزی"},
      {value: "blog", text: "دسته‌بندی مقالات"},
    ];
  },

  typesOpetion() {
    return [
      {value: "main", text: "صفحه اصلی"},
      {value: "job", text: "لیست فرصت های کارآموزی"},
      {value: "intern", text: "لیست کارآموزان"},
      {value: "employer", text: "لیست کارفرمایان"},
      // {value: "super", text: "لیست ویژه"},
    ];
  },

  alignOpetion() {
    return [
      {value: "top", text: "بالای متن"},
      {value: "bottom", text: "پایین متن"},
      {value: "right", text: "سمت راست متن"},
      {value: "left", text: "سمت چپ متن"},
    ];
  },

  educationLevels() {
    return [
      {value: "Under Diploma", text: "زیر دیپلم"},
      {value: "Diploma", text: "دیپلم"},
      {value: "Bachelor", text: "کارشناسی"},
      {value: "phD", text: "دکتری"},
    ];
  },

  contentTypesOpetion() {
    return [
      {value: "main", text: "صفحه اصلی"},
      {value: "about", text: "صفحه درباره ما"},
      {value: "introduction", text: "قوانین و مقررات"},
    ];
  },

  experienceItems() {
    return [
      { value: "1", text: "۱ سال" },
      { value: "2", text: "۲ سال" },
      { value: "3", text: "۳ سال" },
      { value: "4", text: "۴ سال" },
      { value: "5", text: "بالای ۵ سال" },
      { value: "10", text: "بالای 10 سال" },
    ];
  },

};
