<template>
  <router-view />
</template>

<script>
import '@fortawesome/fontawesome-free/css/all.css'
import "@/assets/icomoon/style.css";
import 'devextreme/dist/css/dx.light.css'

export default {
  name: 'App'
};
</script>

<style lang="sass">
@import "@/assets/styles/style"
</style>
