let language = localStorage.getItem("selectedLanguage");
let label;
if (language && language === "En") {
  label = {
    page: {
      home: "Home",
      dashboard: "Dashboard",
      aboutUs: "About Us",
      contactUs: "Contact Us",
      baseInformation: "Base Informations",
      users: "Users",
      addUser: "New User",
      editUser: "Edit User Info",
      products: "Products",
      addProduct: "New Product",
      editProduct: "Edit Product",
      orders: "Orders",
      addOrder: "New Order",
      editOrder: "Edit Order",
      support: "Support",
      tickets: "Tickets",
      addTicket: "Open Ticket",
      messages: "Messages",
      profile: "Profile",
      login: "Sign In",
      register: "Sign Up",
      notFound: "Oops! Are You Lost ?!",
      notAccess: "Sorry! You Don't Have Access To This Page",
      noAccess: "No Access",
      serverError: "Internal Server Error! Please Call The Technical Support",
      underConstruction:
        "This Page is Under Construction and will be Available Soon",
      colors: "colors List",
      designTypes: "Design Types List",
      commodities: "Raw Materials List",
      packing: "packaging",
      machine: "Machines List",
      template: "Template List",
      productPrice: "Product Price List",
      benefitsAndDeduction: "Benefits And Deduction List",
      fiscalYear: "Fiscal Year",
      bom: "Production formula",
      workCalendar: "Work Calendar",
      annualBase: "Annual Base",
      unitPosition: "Unit Position List",
      ContractJobTitles: "Contract Job Title",
      InsuranceJobTitles: "Insurance Job Title",
      bank: "Bank Accounts",
      person: "Persons",
      manufacturing: "Manufacturing Report",
      accountInfo: "Accounts Info",
      tax: "Tax List",
      company: "Companies",
      InputOutput: "Input Output",
      suppliers: "Suppliers",
      AddSuppliers: "Add Suppliers",
      EditSuppliers: "Edit Suppliers",
      customers: "Customers",
      AddCustomer: "Add Customer",
      EditCustomer: "Edit Customer",
      Roles: "Roles",
      employees: "Employees",
      AddEmployee: "Add Employee",
      EditEmployee: "Edit Employee",
      RolePermissions: "Role Permissions",
      EmployeeContract: "Employee Contract",
      AddEmployeeContract: "Add Employee Contract",
      EditEmployeeContract: "Edit Employee Contract",
      store: "store",
      accounting: "Accounting",
      ReceiveAndPay: "Receive And Pay",
      Trading: "Trading",
      Buy: "Buy",
      AddBuy: "Add Buy",
      EditBuy: "Edit Buy",
      ReturnBuy: "Return Buy",
      AddReturnBuy: "Add Return Buy",
      EditReturnBuy: "Edit Return Buy",
      Sale: "Sale",
      AddSale: "Add Sale",
      EditSale: "Edit Sale",
      ReturnSale: "Return Sale",
      AddReturnSale: "Add Return Sale",
      EditReturnSale: "Edit Return Sale",
      productionControl: "production Control",
      salary: "Salary",
      payslip: "payslip",
      addPayslip: "Add Payslip",
      editPayslip: "Edit Payslip",
      peopleManagement: "People Management",
      RolesAccess: "Roles And Access",
      Employees: "Employees",
      Customers: "Customers",
      reports: "Reports",
      SoftwarePresentation: "Software Presentation",
      Balance: "Balance",
      ProductSales: "Dashboard sales product",
      ReportPersonSalary: "Person Salary Report",
      SalesOfficeByCommodity: "Sales Office By Commodity",
      SalesOfficeByFactor: "Sales Office By Factor",
      StoreInventory: "Store Inventory",
      numberStoreInventory: "Numerical warehouse stocks Report",
      ConsumingMaterial: "Consuming Material By Commodity ",
      AccountListOperation: "Account List Operation",
      TotalConsumption: "Total Consumption",
      reciepts: "Reciepts",
      drafts: "Draft",
      addPerson: "New Person",
      editPerson: "Update Person",
      buySaleByMonth: "Sale And Buy by Month Report",
      personnelProduction: "Personnel Production Report",
      PackagingAndCartons: "Packaging And Cartons",
      PackagingInfo: "Packaging Information",
    },
    menu: {
      home: "Home",
      dashboard: "Dashboard",
      about: "About Us",
      contact: "Contact Us",
      users: "Users",
      messages: "Messages",
      tickets: "Tickets",
      support: "Support",
      products: "Products",
      manageProducts: "Products Management",
      orders: "Orders",
      addOrder: "New Order",
      cart: "Cart",
      shop: "Shop",
      baseInformation: "Base Informations",
      editProfile: "Update Profile",
      notifications: "Notifications",
      allNotifications: "Show all Notifications",
      allMessages: "Show all Messages",
      logout: "Logout",
      rejectedTemplate: "Blocked Account",
      colors: "Colors",
      designTypes: "Design Types",
      commodities: "Raw Materials And Products",
      packing: "Packages",
      machine: "Machines",
      template: "Templates",
      productPrice: "Product Price",
      benefitsAndDeduction: "Benefits And Deduction",
      fiscalYear: "Fiscal Year",
      bom: "Production formula",
      workCalendar: "Work Calendar",
      annualBase: "Annual Base",
      unitPosition: "Unit Position",
      bank: "Bank Accounts",
      person: "Persons",
      manufacturing: "Manufacturing",
      accountInfo: "Account Info",
      tax: "Tax",
      InputOutput: "Input & Output",
      suppliers: "Suppliers",
      company: "Companies",
      employeeContract: "Employee Contract",
      store: "Store",
      accounting: "Accounting",
      ReceiveAndPay: "Receive And Pay",
      Trading: "Trading and Sale",
      Buy: "Buy",
      returnBuy: "Purchase Return",
      Sale: "Sale",
      ReturnSale: "Sales return",
      productionControl: "Production Control",
      salary: "Salary",
      payslip: "Payslip",
      addPayslip: "Add Payslip",
      peopleManagement: "People Management",
      Roles: "Roles",
      Employees: "Employees",
      Customers: "Customers",
      reports: "Reports",
      SoftwarePresentation: "Software Presentation",
      Balance: "Balance",
      ProductSales: "Product sales dashboard",
      ReportPersonSalary: "Report Person Salary",
      SalesOfficeByCommodity: "Sales Office By Commodity",
      SalesOfficeByFactor: "Sales Office By Factor",
      StoreInventory: "Store Inventory",
      numberStoreInventory: "Number of warehouse stocks",
      ConsumingMaterial: "Consuming Material By Commodity",
      AccountListOperation: "Account List Operation",
      TotalConsumption: "Total Consumption",
      measurementUnit: "Measurement Unit",
      reciepts: "Reciepts",
      drafts: "Draft",
      buySaleByMonth: "Sale And Buy by Month",
      personnelProduction: "Personnel Production",
    },
    button: {
      login: "Sign In",
      register: "Sign Up",
      logout: "Logout",
      recoveryEmail: "Send Recovery Email",
      submit: "Submit",
      submitInfo: "Submit Informations",
      save: "Save",
      saveChanges: "Save Changes",
      add: "Add New",
      addUser: "New User",
      edit: "Update",
      delete: "Delete",
      confirmDelete: "Yes, Delete",
      editProfile: "Update Profile",
      editPassword: "Update Password",
      send: "Send",
      back: "Back",
      backToLogin: "Back to Login",
      backToHome: "Back to Home",
      home: "Home",
      cancel: "Cancel",
      addBase: "Add Base Info",
      reload: "Reload",
      addProduct: "Save Product",
      editProduct: "Update Profile",
      recover: "Recover",
      deletedUsers: "Deleted Users",
      passwordRecovery: "Password Recovery",
      permissions: "Permissions",
      addRole: "New Role",
      addColor: "New Color",
      confirmColor: "Confirm And Select Color",
      addDesign: "New Design",
      addCommodity: "New Commodity",
      addPacking: "New Packing",
      addPackingInfo: "New Packing Information",
      addMachine: "New Machine",
      addTemplate: "New Template",
      addProductPrice: "New Product Price",
      addBenefitsAndDeduction: "New Benefits And Deduction",
      addFiscalYear: "New Fiscal Year",
      addFormula: "New Formula",
      detail: "Details",
      addBOM: "New BOM",
      groupEditing: "Group Editing",
      addJobTitle: "New Job Title",
      addBank: "Add Bank",
      addPerson: "Add Person",
      addProduction: "Add Production Program",
      addTax: "New Tax",
      addInputOutput: "Submit System Transactions",
      addSale: "New Sale",
      addStore: "New Store",
      closeWindow: "Close Window",
      addDraft: "New Draft",
      addReceipt: "New Receipt",
      showDetail: "Show Detail",
      search: "Search",
    },
    table: {
      row: "#",
      actions: "Actions",
      title: "Title",
      qty: "Quantity",
      category: "Category",
      subject: "Subject",
      status: "Status",
      name: "Full Name",
      username: "Username",
      city: "City",
      role: "User Role",
      active: "Active",
      inactive: "Inactive",
      legal: "حقوقی",
      actual: "حقیقی",
      firstName: "First Name",
      lastName: "Last Name",
      companyOrFullName: "Company/Full Name",
      fatherName: "Father Name",
      mobile: "Mobile Number",
      phone: "Telephone",
      nationalCode: "National Code",
      managerNationalCode: "Manager National Code",
      economicCode: "Economic Code",
      nationalId: "National Id",
      registrationNumber: "Registration Number",
      fax: "Fax",
      address: "Address",
      personType: "Person Type",
      createdDate: "Created Date",
      creatorName: "Creator Name",
      updaterName: "Updater Name",
      lastUpdateDate: "Last Update Date",
      email: "Email Address",
      personnelCode: "Personnel Code",
      birthDate: "Birth Date",
      profileImage: "Profile Image",
      color: "Color",
      code: "Code",
      weight: "Weight",
      rawMaterial: "Raw Material",
      product: "Product",
      countInTemplate: "Count In Template",
      maximum: "Maximum",
      minimum: "Minimum",
      height: "Height",
      length: "Length",
      width: "Width",
      design: "Design",
      price: "Price",
      isDependOnWorkDays: "Is Depend On Work Days",
      taxable: "Is Taxable",
      insured: "Is Insured",
      typeTitle: "Type",
      minimumDailyWage: "minimum Daily Wage",
      couponAndGrocery: "Coupon And Grocery",
      housingAllowance: "Housing Allowance",
      consumptionCount: "consumption Count",
      commodityTitle: "Commodity Title",
      productTitle: "Product Title",
      count: "Count",
      monthTitle: "month",
      workDays: "Work Days",
      workHours: "Work Hours",
      workExperience: "Work Experience",
      accountNumber: "Account Number",
      initialBalance: "Initial Balance",
      fullName: "FullName",
      mobileNumber: "Mobile Number",
      dateLocal: "Date",
      totalCount: "Total Count",
      personnel: "Personnel",
      machine: "Machine",
      templateCount: "Template Count",
      productionCount: "Production Count",
      wasteCount: "Waste Count",
      productValue: "Product Value",
      fiscalYear: "Fiscal Year",
      fromPrice: "From Price",
      toPrice: "To Price",
      rate: "Tax Rate",
      amount: "Amount",
      inputOutputTypeId: "Transaction Type",
      creditAccount: "Credit Account",
      debtAccount: "Debt Account",
      subsystem: "Subsystem",
      jobTitle: "Job Title",
      unitPosition: "Job Position",
      employee: "Employees",
      fromDate: "From Date",
      toDate: "To Date",
      dailyWage: "Daily Wage",
      hourlyWage: "Hourly Wage",
      hourlyHours: "Hourly Operation",
      hourlyMonth: "Monthly Operation",
      insuranceWage: "Insurance Wage",
      AnnualBase: "Annual Base",
      gift: "Reward",
      childAllowance: "Children Alliwance",
      bankTitle: "Bank Title",
      accountTitle: "Account Title",
      totalConsumption: "Total Consumption",
      totalProductValue: "Total ProductValue",
      totalWeight: "Total Weight",
      wastePercentage: "Waste Percentage",
      efficiencyPrice: "Efficiency Price",
      citizenShip: "CitizenShip",
      store: "Store",
      supplier: "Supplier",
      description: "Description",
      customer: "Customer",
      receiptType: "Receipt Type",
      draftType: "Draft Type",
      taxExemptionCoefficient: "Tax Exemption Coefficient",
    },
    form: {
      search: "Search ...",
      firstName: "First Name",
      lastName: "Last Name",
      fullName: "Full Name",
      userName: "Username",
      password: "Password",
      repeatPassword: "Repeat Password",
      currentPassword: "Current Password",
      newPassword: "New Password",
      email: "Email Address",
      mobile: "Mobile Number",
      tel: "Phone Number",
      phone: "Telephone",
      address: "Address",
      zipCode: "Zip Code",
      otpCode: "OTP Code",
      image: "Image",
      file: "File",
      fileSize: "Max File Size",
      fileType: "File Types",
      fileRatio: "Better To Use Square Ratio Picture",
      department: "Department",
      birthDate: "Birth Date",
      description: "Description",
      content: "Content",
      message: "Message",
      subject: "Subject",
      title: "Title",
      qty: "Quantity",
      digit: "Digit",
      category: "Category",
      city: "City",
      role: "User Role",
      productName: "Product Name",
      annualDemand: "Annual Demand",
      sbu: "SBU",
      resell: "Resell",
      sendDate: "Sent Date",
      productDate: "Production Date",
      paymentMethod: "Payment Method",
      cashOption: "Cash",
      chequeOption: "Cheque",
      attachment: "Attachment",
      dropzoneTxt: "Click or Drop File here",
      personType: "Person Type",
      legal: "Legal",
      actual: "Actual",
      nationalCode: "National Code",
      issuedAt: "Issued From",
      birthCertificateNo: "Birth Certificate Number",
      placeOfBirth: "Birth Place",
      fatherName: "Father Name",
      economicCode: "Economic Code",
      nationalId: "National Id",
      registrationNumber: "Registration Number",
      fax: "Fax",
      managerNationalCode: "Manager National Code",
      customerStatus: "Customer Status",
      active: "Active",
      inActive: "Inactive",
      companyName: "Company Name",
      personnelCode: "Personnel Code",
      accountStatus: "Account Status",
      hiringDate: "Hiring Date",
      childrenCount: "Children Count",
      insuranceNumber: "Insurance Number",
      insuranceWorkExperience: "Insurance Work Experience",
      cardNumber: "Card Number",
      accountNumber: "Account Number",
      citizenShip: "Citizen Ship",
      iranian: "Iranian",
      foreigners: "Foreigners",
      employeeStatus: "Employee Status",
      weight: "Weight",
      rawMaterial: "Raw Material",
      product: "Product",
      countInTemplate: "Count In Template",
      maximum: "Maximum",
      minimum: "Minimum",
      code: "Code",
      design: "Design",
      color: "Color",
      price: "Price",
      isDependOnWorkDays: "Is Depend On Work Days",
      taxable: "Is Taxable",
      insured: "Is Insured",
      typeTitle: "Type",
      minimumDailyWage: "minimum Daily Wage",
      couponAndGrocery: "Coupon And Grocery",
      housingAllowance: "Housing Allowance",
      consumptionCount: "Used Count",
      commodityTitle: "Commodity",
      count: "Count",
      workDays: "Work Days",
      workExperience: "Work Experience",
      initialBalance: "Initial Balance",
      account: "Account",
      mobileNumber: "Mobile",
      date: "Date",
      totalCount: "Total",
      personnel: "Personnel",
      machine: "Machine",
      templateCount: "Mold Count",
      productionCount: "Production Count",
      wasteCount: "Waste Count",
      productValue: "Commodity Value",
      bom: "BOM",
      fiscalYear: "Fiscal Year",
      fromPrice: "From Price",
      toPrice: "To Price",
      rate: "Rate",
      inputOutputTypeId: "Transaction Type",
      creditAccount: "Credit Account",
      debtAccount: "Debt Account",
      subsystem: "Subsystem",
      activity: "Activity Field",
      brand: "Brand",
      managerName: "Manager Name",
      website: "Website",
      typeOfFinancialSystem: "Financial System Type",
      typeOfWarehouseSystem: "Warehouse System Type",
      managerBirthday: "Manager BirthDate",
      primarySubstance: "Raw Materials",
      measurementUnit: "Measurement Unit",
      length: "Length",
      width: "Width",
      height: "Height",
      store: "Store",
      supplier: "Supplier",
      commodity: "Commodity",
      customer: "Customer",
      receiptType: "Receipt Type",
      draftType: "Draft Type",
      managerFirstName: "Manager First Name",
      managerLastName: "Manager Last Name",
      identificationCode: "Identification Code",
      taxExemptionCoefficient: "Tax Exemption Coefficient",
      month: "Month",
    },
    caption: {
      yes: "Yes",
      no: "No",
      ok: "Ok",
      dashboard: "Dashboard",
      profile: "Update Profile",
      password: "Change Password",
      avatar: "Profile Avatar",
      baseInformation: "Basic Informations",
      addBaseInformation: "Add Basic Info",
      editBaseInformation: "Update Basic Info",
      users: "Users Management",
      addUser: "New User",
      editUser: "Update User",
      products: "Products Management",
      orders: "Manage Orders",
      messages: "Messages and Chats",
      tickets: "Tickets",
      loading: "Loading ...",
      singIn: "Sign in",
      singUp: "Sign Up",
      haveAccount: "Do you have Account ?",
      haveNotAccount: "Don't have Account ?",
      forgotPassword: "Forgot Password ?",
      emptyItem: "Item Not Found",
      emptyMessage: "There is no Message",
      emptyTicket: "There is no Tickets",
      emptyNotify: "There is no new Notifications",
      noMessage: "There is no new Message",
      noNotify: "There is no new Notification",
      underConstruction:
        "This Section is Under Construction and will be available soon",
      rejectedTitle: "No Access",
      rejectedText: "Your Account has been blocked or Reported",
      search: "Search",
      searchTable: "Search Items",
      searchAllColumns: "Search in all columns",
      delConfirm: "Are you sure you want to delete this item ?",
      resell: "is this Item for Resell ?",
      aboutUs: "Introduce Parsina Company",
      customers: "Customers Management",
      emptyList: "The List is empty",
      emptyRolesList: "The list of roles is empty",
      deletedUsers: "Deleted Users List",
      emptyUsersList: "The list of deleted users is empty",
      restorePassConfirm: "Yes, Restore",
      permissions: "Manage and Edit role access",
      rolesDescription:
        "Select the desired permissions for this role and press the register button",
      roles: "مدیریت نقش و دسترسی‌های کاربران",
      addRole: "ثبت نقش جدید",
      editRole: "ویرایش نقش",
      cityCode: "Enter along with the city code",
      insuranceWork: "Enter based on the number of years",
      cardNumber: "Write without dashes and spaces",
      address: "Please write in full along with the name of the city",
      addColor: "New Color",
      editColor: "Update Color",
      colors: "Colors Management",
      designTypes: "Design Types Management",
      addDesign: "New Design",
      editDesign: "Update Design",
      commodity: "Raw Materials And Products Management",
      addCommodity: "New Commodity",
      editCommodity: "Update Commodity",
      addPacking: "New Packing",
      packingTypes: "Package Management",
      editPacking: "Update Packing",
      machineTypes: "Machine Management",
      addMachine: "New Machine",
      editMachine: "Update Machine",
      templateTypes: "Mold Management",
      addTemplate: "New Mold",
      editTemplate: "Update Mold",
      addProductPrice: "New Product Price",
      editProductPrice: "Update Product Price",
      addBenefitsAndDeduction: "New Benefits and Deductions",
      editBenefitsAndDeduction: "Update Benefits and Deductions",
      addFiscalYear: "New FiscalYear",
      editFiscalYear: "Update FiscalYear",
      atLeastFormula: "Fill the Form form BOM (Formula)",
      addBOM: "New BOM",
      editBOM: "Update BOM",
      editWorkCalender: "Update Work Calender",
      fiscalYear: "Fiscal Year",
      editAnnualBase: "Edit Annual Base",
      addJobTitle: "New Job Title",
      editJobTitle: "Update Job Title",
      addBank: "New Bank",
      editBank: "Update Bank",
      addPerson: "New Person",
      editPerson: "Update Person",
      addProduction: "New Production Program",
      editProduction: "Update Production Program",
      addTax: "New Tax",
      editTax: "Edit Tax",
      addInputOutput: "New System Transactions",
      editInputOutput: "Update System Transactions",
      addStore: "New Warehouse",
      editStore: "Update Warehouse",
      inputOutput: "Input, Output, Receive and Payment Type",
      addUnit: "Add Measurement Unit",
      editUnit: "Edit Measurement Unit",
      atLeastCommodity:
        "To register reciept, you must register at least one commodity",
      addReceipt: "New Receipt",
      editReceipt: "Update Receipt",
      editCommodityDetail: "Update Commodity Detail",
      addDraft: "New Draft",
      editDraft: "Update Draft",
      editProductDetail: "Update Product Detail",
      atLeastProduct:
        "To register Draft, you must register at least one Product",
      receiptDetail: "Show Receipt Detail",
      draftDetail: "Show Draft Detail",
    },
  };
} else {
  label = {
    page: {
      home: "صفحه اصلی",
      dashboard: "داشبورد",
      aboutUs: "معرفی نرم افزار",
      contactUs: "تماس با ما",
      baseInformation: "اطلاعات پایه",
      users: "کاربران ",
      addUser: "معرفی کاربر جدید",
      editUser: "ویرایش اطلاعات کاربر",
      products: "محصولات و تولیدات",
      addProduct: "ثبت محصول",
      editProduct: "ویرایش اطلاعات محصول",
      orders: "سفارشات مشتریان",
      addOrder: "ثبت سفارش",
      editOrder: "ویرایش سفارش",
      support: "پشتیبانی",
      tickets: "تیکت پشتیبانی",
      addTicket: "ثبت تیکت پشتیبانی",
      messages: "پیام‌ها",
      profile: "پروفایل کاربری",
      login: "ورود به سامانه",
      register: "عضویت",
      notFound: "راهتان را گم کرده‌اید ؟!",
      notAccess: "متأسفانه دسترسی شما برای مشاهده این قسمت محدود شده است",
      noAccess: "عدم دسترسی",
      serverError: "خطای ارتباط با سرور؛ لطفا به پشتیبانی فنی اطلاع دهید",
      underConstruction: "صفحه مدنظر در حال توسعه و برورسانی می‌باشد",
      colors: "لیست رنگ ها",
      designTypes: "لیست طرح ها",
      commodities: "مواد اولیه و کالاها",
      packing: "بسته بندی ها",
      machine: "ماشین آلات و دستگاه ها ",
      template: "قالب ها",
      productPrice: "محصولات",
      benefitsAndDeduction: "مزایا و کسورات",
      fiscalYear: "سال مالی",
      bom: "فرمول تولید",
      workCalendar: "تقویم کاری",
      annualBase: "پایه سنوات",
      unitPosition: "سمت و عناوین کارآموزی قراردادی",
      ContractJobTitles: "عنوان کارآموزی قراردادی ",
      InsuranceJobTitles: "عنوان کارآموزی بیمه",
      bank: "حساب های بانکی",
      person: "اشخاص",
      manufacturing: "گزارش تولید",
      accountInfo: "سرفصل های حساب",
      tax: "جدول مالیاتی",
      company: "تنظیمات و نمایش اطلاعات سازمان",
      InputOutput: "تراکنش های سیستم",
      suppliers: "تامین کنندگان",
      AddSuppliers: "ثبت تامین کنندگان",
      EditSuppliers: "ویرایش تامین کنندگان",
      customers: "مشتریان",
      AddCustomer: "ثبت مشتریان",
      EditCustomer: "ویرایش مشتریان",
      Roles: "نقش های کاربران",
      employees: "پرسنل",
      AddEmployee: "ثبت کارمند",
      EditEmployee: "ویرایش کارمندان",
      RolePermissions: "دسترسی های نقش",
      EmployeeContract: "قرارداد پرسنل",
      AddEmployeeContract: "ثبت قرارداد پرسنل",
      EditEmployeeContract: "ویرایش قرارداد پرسنل",
      store: "انبار",
      accounting: "حسابداری و خزانه",
      ReceiveAndPay: "دریافت و پرداخت",
      Trading: "بازرگانی و فروش",
      Buy: "خرید",
      AddBuy: "ثبت خرید",
      EditBuy: "ویرایش خرید",
      ReturnBuy: "مرجوعی خرید",
      AddReturnBuy: "ثبت مرجوعی خرید",
      EditReturnBuy: "ویرایش مرجوعی خرید",
      Sale: "فروش",
      AddSale: "ثبت فروش",
      EditSale: "ویرایش فروش",
      ReturnSale: "مرجوعی فروش",
      AddReturnSale: "ثبت مرجوعی فروش",
      EditReturnSale: "ویرایش مرجوعی فروش",
      productionControl: "کنترل تولید",
      salary: " گزارش حقوق و دستمزد",
      payslip: "فیش حقوقی",
      addPayslip: "محاسبه حقوق",
      editPayslip: "ویرایش فیش حقوقی",
      peopleManagement: "مدیریت اشخاص",
      RolesAccess: "دسترسی های نقش",
      Employees: "پرسنل",
      Customers: "مشتریان",
      reports: "گزارشات",
      SoftwarePresentation: "معرفی نرم افزار",
      Balance: " گزارش تراز",
      ProductSales: "گزارش داشبورد فروش محصول",
      ReportPersonSalary: "حقوق و دستمزد شخص",
      SalesOfficeByCommodity: " گزارش دفتر فروش بر اساس کالا",
      SalesOfficeByFactor: "گزارش دفتر فروش بر اساس فاکتور",
      StoreInventory: "گزارش موجودی انبار",
      numberStoreInventory: "گزارش تعدادی موجودی انبار",
      ConsumingMaterial: "گزارش مصرف مواد بر اساس محصول",
      AccountListOperation: " گزارش دفتر حساب",
      TotalConsumption: "مصرف مواد",
      reciepts: "رسید های انبار",
      drafts: "حواله های انبار",
      addPerson: "ثبت اطلاعات شخص",
      editPerson: "ویرایش اطلاعات شخص",
      productionConsumable: "گزارش تولید مواد مصرفی",
      printSetting: "تنظیمات چاپ و پرینت سامانه",
      buySaleByMonth: "گزارش خرید و فروش بر اساس ماه",
      personnelProduction: "گزارش عملکرد پرسنل",
      PackagingAndCartons: "انواع بسته بندی و کارتن",
      PackagingInfo: "اطلاعات بسته بندی",
    },
    menu: {
      home: "صفحه اصلی",
      dashboard: "داشبورد",
      about: "معرفی نرم افزار",
      contact: "راه های ارتباطی",
      users: "کاربران ",
      messages: "پیام‌ها",
      tickets: "تیکت‌ها",
      support: "پشتیبانی",
      manageProducts: "مدیریت محصولات",
      products: "محصولات",
      orders: "سفارشات",
      addOrder: "ثبت سفارش",
      cart: "سبد خرید",
      shop: "فروشگاه",
      baseInformation: "اطلاعات پایه",
      editProfile: "ویرایش پروفایل",
      notifications: "اعلان و هشدار‌ها",
      allNotifications: "مشاهده همه اعلان‌ها",
      allMessages: "مشاهده همه پیام‌ها",
      logout: "خروج",
      rejectedTemplate: "کاربر مسدود شده",
      colors: "رنگ ها",
      designTypes: "انواع طرح",
      commodities: "مواد اولیه و کالاها",
      packing: "بسته بندی",
      machine: "ماشین آلات و دستگاه ها ",
      template: "قالب ها",
      productPrice: "محصولات",
      benefitsAndDeduction: "مزایا و کسورات حقوق",
      fiscalYear: "سال مالی",
      bom: "فرمول تولید",
      workCalendar: "تقویم کاری",
      annualBase: "پایه سنوات",
      unitPosition: "عناوین کارآموزی",
      bank: "حساب های بانکی",
      person: "اشخاص",
      manufacturing: "گزارش تولید",
      accountInfo: "سرفصل های حساب",
      tax: "جدول مالیاتی",
      InputOutput: "تراکنش های سیستم",
      suppliers: "تامین کنندگان",
      company: "تنظیمات سازمان",
      employeeContract: "قرارداد  پرسنل",
      store: "انبار",
      accounting: "حسابداری و خزانه",
      ReceiveAndPay: "دریافت و پرداخت",
      Trading: "بازرگانی و فروش",
      Buy: "خرید",
      returnBuy: "مرجوعی خرید",
      Sale: "فروش",
      ReturnSale: "مرجوعی فروش",
      productionControl: "کنترل تولید",
      salary: "حقوق و دستمزد",
      payslip: "فیش حقوقی",
      addPayslip: "محاسبه حقوق",
      peopleManagement: "مدیریت اشخاص",
      Roles: "نقش و دسترسی‌ها",
      Employees: "پرسنل",
      Customers: "مشتریان",
      reports: "گزارشات",
      SoftwarePresentation: "معرفی نرم افزار",
      Balance: "تراز",
      ProductSales: "داشبورد فروش محصول",
      ReportPersonSalary: "حقوق و دستمزد شخص",
      SalesOfficeByCommodity: "دفتر فروش بر اساس کالا",
      SalesOfficeByFactor: "دفتر فروش بر اساس فاکتور",
      StoreInventory: "موجودی انبار",
      numberStoreInventory: "موجودی تعدادی انبار",
      ConsumingMaterial: "مصرف مواد بر اساس محصول",
      AccountListOperation: "دفتر حساب",
      TotalConsumption: "مصرف مواد",
      measurementUnit: "واحد اندازه‌گیری",
      reciepts: "رسید های انبار",
      drafts: "حواله های انبار",
      productionConsumable: "تولید مواد مصرفی",
      printSetting: "تنظیمات چاپ و پرینت",
      buySaleByMonth: "خرید و فروش بر اساس ماه",
      personnelProduction: "عملکرد پرسنل",
      opertaion: "عملیات",
    },
    button: {
      login: "ورود به سامانه",
      register: "ثبت نام در سامانه",
      logout: "خروج از سامانه",
      recoveryEmail: "ارسال ایمیل بازیابی",
      submit: "ثبت",
      submitInfo: "ثبت اطلاعات",
      save: "ذخیره",
      saveChanges: "ثبت تغییرات",
      add: "ثبت جدید",
      addUser: "معرفی کاربر جدید",
      edit: "ویرایش",
      delete: "حذف",
      confirmDelete: "بله، حذف شود",
      editProfile: "ویرایش پروفایل",
      editPassword: "ویرایش رمز عبور",
      send: "ارسال",
      back: "بازگشت",
      backToLogin: "بازگشت به صفحه ورود",
      backToHome: "بازگشت به صفحه اصلی",
      home: "صفحه اصلی",
      cancel: "انصراف",
      addBase: "ثبت اطلاعات پایه",
      reload: "بارگذاری مجدد",
      addProduct: "افزودن محصول",
      editProduct: "ویرایش محصول",
      recover: "بازیابی",
      deletedUsers: "کاربران حذف شده",
      passwordRecovery: "بازیابی رمز عبور",
      permissions: "دسترسی‌ ها",
      addRole: "ثبت نقش",
      addColor: "ثبت رنگ",
      confirmColor: "تأیید و انتخاب رنگ",
      addDesign: "ثبت طرح",
      rawMaterial: "ثبت مواد اولیه",
      addPacking: "ثبت بسته بندی",
      addPackingInfo: "ثبت اطلاعات بسته بندی",
      addMachine: "ثبت دستگاه",
      addTemplate: "ثبت قالب",
      addProductPrice: "ثبت محصول",
      addBenefitsAndDeduction: "ثبت مزایا و کسورات ",
      addFiscalYear: "ثبت سال مالی",
      addFormula: "ثبت فرمول",
      detail: "جزییات",
      addBOM: "ثبت فرمول تولید ",
      groupEditing: "ویرایش گروهی",
      addJobTitle: "ثبت عنوان کارآموزی",
      addBank: "ثبت حساب بانکی",
      addPerson: "ثبت شخص",
      addProduction: "ثبت برنامه ی تولید",
      addTax: "ثبت مالیات ",
      addInputOutput: "ثبت تراکنش های سیستم",
      addSale: "ثبت خرید",
      addStore: "ثبت انبار",
      addReceipt: "ثبت رسید",
      closeWindow: "بستن پنجره",
      addCommodity: "افزودن",
      addDraft: "ثبت حواله",
      showDetail: "نمایش جزئیات",
      search: "جستجو",
    },
    table: {
      row: "ردیف",
      actions: "عملیات",
      title: "عنوان",
      qty: "تعداد",
      category: "دسته بندی",
      subject: "موضوع",
      status: "وضعیت",
      name: "نام و نام خانوادگی",
      username: "نام کاربری",
      city: "شهر / استان",
      role: "نقش کاربر",
      active: "فعال",
      inactive: "غیرفعال",
      legal: "حقوقی",
      actual: "حقیقی",
      firstName: "نام",
      lastName: "نام خانوادگی",
      companyOrFullName: "نام کامل/شرکت",
      fatherName: "نام پدر",
      mobile: "تلفن همراه",
      phone: "تلفن ثابت",
      nationalCode: "کد ملی",
      managerNationalCode: "کد ملی مدیر",
      economicCode: "کد اقتصادی",
      nationalId: "شناسه ملی",
      registrationNumber: "شماره ثبت",
      fax: "فکس",
      address: "آدرس",
      personType: "نوع شخص",
      createdDate: "تاریخ ثبت",
      creatorName: "کاربر ثبت کننده",
      updaterName: "کاربر ویرایش کننده",
      lastUpdateDate: "تاریخ آخرین ویرایش",
      email: "پست الکترونیک",
      personnelCode: "کد پرسنلی",
      birthDate: "تاریخ تولد",
      profileImage: "تصویر پروفایل",
      color: "رنگ",
      code: "کد",
      weight: "وزن",
      rawMaterial: "مواد اولیه",
      product: "محصول",
      countInTemplate: "تعداد در قالب",
      maximum: "حداکثر تولید",
      minimum: "حداقل تولید",
      height: "ارتفاع",
      length: "طول",
      width: "عرض",
      design: "طرح",
      price: "مبلغ",
      isDependOnWorkDays: "بر اساس روزهای کاری",
      taxable: "مشمول مالیات",
      insured: "مشمول بیمه",
      typeTitle: "نوع ",
      minimumDailyWage: "حداقل دستمزد روزانه",
      couponAndGrocery: "بن و خواربار",
      housingAllowance: "حق مسکن",
      consumptionCount: "مقدار مصرف",
      commodityTitle: "عنوان کالا",
      productTitle: "عنوان محصول",
      count: "مقدار / تعداد",
      monthTitle: "ماه",
      workDays: "تعداد روز های کاری",
      monthDays: "تعداد روز های ماه",
      workHours: "ساعت های کاری",
      workExperience: "تجربه کاری",
      accountNumber: "شماره حساب",
      initialBalance: "موجودی اولیه",
      fullName: "نام و نام خانوادگی",
      mobileNumber: "شماره موبایل",
      date: "تاریخ",
      totalCount: "جمع کل",
      personnel: "نام پرسنل",
      machine: "نام دستگاه",
      templateCount: "تعداد قالب",
      productionCount: "تعداد تولید",
      wasteCount: "تعداد ضایعات",
      productValue: "ارزش محصول",
      fiscalYear: "سال مالی",
      fromPrice: "از مبلغ",
      toPrice: "تا مبلغ",
      rate: "مقدار مالیات",
      amount: "مقدار",
      inputOutputTypeId: "نوع تراکنش",
      creditAccount: "سرفصل بستانکار ",
      debtAccount: "سرفصل بدهکار ",
      subsystem: "زیر سیستم",
      jobTitle: "عنوان کارآموزی",
      unitPosition: "موقیت کارآموزی",
      employee: "کارمندان",
      fromDate: "از تاریخ",
      toDate: "تا تاریخ",
      dailyWage: "دستمزد روزانه",
      hourlyWage: "دستمزد ساعتی",
      hourlyHours: "کارکرد ساعتی",
      hourlyMonth: "کارکرد ماهانه",
      insuranceWage: "دستمزد بیمه",
      AnnualBase: "پایه سنوات",
      gift: "پاداش",
      childAllowance: "حق اولاد",
      bankTitle: "عنوان بانک",
      accountTitle: "عنوان حساب",
      totalConsumption: "کل مصرف",
      totalProductValue: "کل ارزش محصول",
      totalWeight: "وزن کل",
      wastePercentage: "درصد ضایعات",
      efficiencyPrice: "بهره وری",
      citizenShip: "تابعیت",
      store: "انبار",
      supplier: "نام تأمین کننده",
      description: "توضیحات",
      customer: "نام مشتری",
      receiptType: "نوع رسید",
      draftType: "نوع حوالا",
      taxExemptionCoefficient: "ضریب معافیت مالیاتی",

      packingType: "نوع بسته بندی",
      countInPack: "تعداد در بسته بندی",
      productWeight: "وزن محصول",
      packingWeight: "مجموع وزن بسته بندی",
      measurementUnit: "واحد اندازه‌گیری",
      buyType: "نوع خرید",
      image: "تصویر",
    },
    form: {
      search: "جستجو ...",
      firstName: "نام",
      lastName: "نام خانوادگی",
      fullName: "نام و نام خانوادگی",
      userName: "نام کاربری",
      password: "رمز عبور",
      repeatPassword: "تکرار رمز عبور",
      currentPassword: "رمز عبور فعلی",
      newPassword: "رمز عبور جدید",
      email: "پست الکترونیک",
      mobile: "تلفن همراه",
      tel: "شماره تماس",
      phone: "تلفن ثابت",
      address: "آدرس",
      zipCode: "کد پستی",
      otpCode: "کد احراز هویت (کد تأیید)",
      image: "تصویر",
      file: "فایل",
      fileSize: "حداکثر حجم مجاز",
      fileType: "پسوندهای مجاز",
      fileRatio: "تصویر در ابعاد مربعی انتخاب کنید",
      department: "دپارتمان",
      birthDate: "تاریخ تولد",
      description: "توضیحات",
      content: "متن و محتوا",
      message: "متن پیام",
      subject: "موضوع",
      title: "عنوان",
      qty: "تعداد",
      digit: "عدد",
      category: "دسته بندی",
      city: "شهر / استان",
      role: "نقش کاربر",
      productName: "نام / عنوان محصول",
      annualDemand: "مقدار نیاز سالانه",
      sbu: "واحد کسب و کار (SBU)",
      resell: "فروش مجدد",
      sendDate: "تاریخ ارسال",
      productDate: "تاریخ تولید",
      paymentMethod: "نحوه پرداخت",
      cashOption: "پرداخت نقدی",
      chequeOption: "چک بانکی",
      attachment: "فایل پیوست",
      dropzoneTxt: "کلیک و یا فایل را در اینجا رها کنید",
      personType: "نوع شخص",
      legal: "حقوقی",
      actual: "حقیقی",
      nationalCode: "کد ملی",
      issuedAt: "محل صدور شناسنامه",
      birthCertificateNo: "شماره شناسنامه",
      placeOfBirth: "محل تولد",
      fatherName: "نام پدر",
      economicCode: "کد اقتصادی",
      nationalId: "شناسه ملی",
      registrationNumber: "شماره ثبت",
      fax: "فکس",
      managerNationalCode: "کد ملی مدیر",
      customerStatus: "وضعیت مشتری",
      active: "فعال",
      inActive: "غیرفعال",
      companyName: "نام شرکت",
      personnelCode: "کد پرسنلی",
      accountStatus: "وضعیت حساب کاربری",
      hiringDate: "تاریخ استخدام",
      childrenCount: "تعداد فرزندان",
      insuranceNumber: "شماره بیمه",
      insuranceWorkExperience: "سابقه کار (بیمه)",
      cardNumber: "شماره کارت بانکی",
      accountNumber: "شماره حساب بانکی",
      citizenShip: "تابعیت",
      iranian: "ایرانی",
      foreigners: "اتباع خارجی",
      employeeStatus: "وضعیت نمایش کارمند",
      weight: "وزن",
      rawMaterial: "مواد اولیه",
      product: "محصول",
      countInTemplate: "تعداد در قالب",
      maximum: "حداکثر تولید",
      minimum: "حداقل تولید",
      code: "کد",
      design: "طرح",
      color: "رنگ",
      price: "مبلغ",
      isDependOnWorkDays: "بر اساس روزهای کاری",
      taxable: "مشمول مالیات",
      insured: "مشمول بیمه",
      typeTitle: "نوع",
      year: "سال",
      minimumDailyWage: "حداقل دستمزد روزانه",
      couponAndGrocery: "بن و خواربار",
      housingAllowance: "حق مسکن",
      consumptionCount: "مقدار مصرف",
      productTitle: "عنوان محصول",
      count: "تعداد محصول",
      workDays: "روز های کاری ",
      workExperience: "سابقه کار ",
      initialBalance: "موجودی اولیه",
      account: "حساب",
      mobileNumber: "شماره موبایل",
      date: "تاریخ",
      totalCount: "جمع کل",
      personnel: "نام پرسنل",
      machine: "نام دستگاه",
      templateCount: "تعداد قالب",
      productionCount: "تعداد تولید",
      wasteCount: "تعداد ضایعات",
      productValue: "ارزش محصول",
      bom: "فرمول تولید",
      fiscalYear: "سال مالی",
      fromPrice: "از مبلغ",
      toPrice: "تا مبلغ",
      rate: "مقدار مالیات",
      inputOutputTypeId: "نوع تراکنش",
      creditAccount: "سرفصل بستانکار ",
      debtAccount: "سرفصل بدهکار ",
      subsystem: "زیر سیستم",
      activity: "زمینه و حوزه فعالیت",
      brand: "نام تجاری و برند",
      managerName: "نام مدیر",
      website: "آدرس وبسایت",
      typeOfFinancialSystem: " نوع سیستم مالی ",
      typeOfWarehouseSystem: "نوع سیستم انبار ",
      managerBirthday: "تاریخ تولد مدیر",
      primarySubstance: "ماده اولیه",
      measurementUnit: "واحد اندازه‌گیری",
      length: "طول",
      width: "عرض",
      height: "ارتفاع",
      store: "انبار",
      supplier: "نام تأمین کننده",
      commodity: "کالا",
      customer: "نام مشتری",
      receiptType: "نوع رسید",
      draftType: "نوع حوالا",
      managerFirstName: "نام مدیر",
      managerLastName: "نام خانوادگی مدیر",
      identificationCode: "کد فراگیر",
      taxExemptionCoefficient: "ضریب معافیت مالیاتی",
      month: "ماه",
      organizations: "سازمان",
      fromDate: "از تاریخ",
      toDate: "تا تاریخ",
      buyType: "نوع خرید",
      buyCommodity: "خرید ماده اولیه",
      buyPacking: "خرید بسته بندی و کارتن",
      packingType: "نوع بسته بندی",
      countInPack: "تعداد در بسته بندی",
      packing: "بسته بندی",
    },
    caption: {
      yes: "بله",
      no: "خیر",
      ok: "تایید",
      dashboard: "داشبورد مدیریتی",
      profile: "ویرایش اطلاعات و رمز عبور حساب کاربری",
      password: "تغییر رمز عبور",
      avatar: "تصویر پروفایل کاربری",
      baseInformation: "اطلاعات و تنظیمات ابتدایی سامانه",
      addBaseInformation: "ثبت اطلاعات پایه جدید",
      editBaseInformation: "ویرایش اطلاعات پایه",
      users: "مدیریت کاربران سامانه",
      addUser: "ثبت کاربر جدید",
      editUser: "ویرایش اطلاعات کاربر",
      products: "مدیریت محصولات و تولیدات",
      orders: "مدیریت و بررسی سفارشات",
      messages: "پیام‌ها و چت کاربران",
      tickets: "پیام‌ها و درخواست‌های مشتریان",
      loading: "در حال بارگذاری ...",
      singIn: "ورود به سامانه",
      singUp: "عضویت در سامانه",
      haveAccount: "حساب کاربری دارید؟",
      haveNotAccount: "حساب کاربری ندارید؟",
      forgotPassword: "رمز عبور را فراموش کرده‌اید؟",
      emptyItem: "موردی جهت نمایش یافت نشد",
      emptyMessage: "لیست پیام‌های شما خالی می‌باشد",
      emptyTicket: "لیست تیکت‌های شما خالی می‌باشد",
      emptyNotify: "لیست اعلان‌ و هشدارها خالی می‌باشد",
      noMessage: "پیام جدیدی دریافت نکردید",
      noNotify: "اعلان جدیدی وجود ندارد",
      underConstruction:
        "این قسمت در حال توسعه می‌باشد و بزودی در دسترس قرار خواهد گرفت",
      rejectedTitle: "عدم دسترسی به سامانه",
      rejectedText: "حساب کاربری شما در این سامانه مسدود شده است",
      search: "جستجو",
      searchTable: "جستجو در جدول",
      searchAllColumns: "جستجو در همه ستون‌ها",
      delConfirm: "از حذف این مورد مطمئن هستید؟",
      resell: "آیا محصول برای فروش مجدد هست؟",
      aboutUs: "معرفی وب اپلیکیشن ماهان شرکت پارسینا",
      customers: "مدیریت مشتریان سامانه",
      emptyList: "لیست خالی میباشد",
      emptyRolesList: "لیست نقش‌ها خالی میباشد",
      deletedUsers: "لیست کاربران حذف شده از سامانه",
      emptyUsersList: "لیست کاربران حذف شده خالی می‌باشد",
      restorePassConfirm: "بله، بازیابی شود",
      permissions: "مدیریت و ویرایش دسترسی‌ های نقش",
      rolesDescription:
        "دسترسی‌های مدنظر برای این نقش را انتخاب کرده و کلید ثبت را بزنید",
      roles: "مدیریت نقش و دسترسی‌های کاربران",
      addRole: "ثبت نقش جدید",
      editRole: "ویرایش نقش",
      cityCode: "همراه با کد شهر وارد کنید",
      insuranceWork: "بر اساس تعداد سال وارد نمایید",
      cardNumber: "بدون خط تیره و فاصله نوشته شود",
      address: "لطفا همراه با نام شهر و به صورت کامل بنویسید",
      addColor: "ثبت رنگ جدید",
      editColor: "ویرایش رنگ",
      colors: "مدیریت رنگ ها",
      designTypes: "مدیریت انواع طرح ها",
      addDesign: "ثبت طرح جدید",
      editDesign: "ویرایش طرح",
      commodity: "مدیریت مواد اولیه و کالای مورد استفاده در سامانه",
      addCommodity: "ثبت مواد اولیه جدید",
      editCommodity: "ویرایش مواد اولیه",
      addPacking: "ثبت بسته بندی جدید",
      packingTypes: "مدیریت انواع بسته بندی ",
      editPacking: "ویرایش بسته بندی",
      machineTypes: "مدیریت انواع ماشین آلات",
      addMachine: "ثبت دستگاه جدید",
      editMachine: "ویرایش دستگاه",
      templateTypes: "مدیریت انواع قالب ",
      addTemplate: "ثبت قالب جدید",
      editTemplate: "ویرایش قالب",
      addProductPrice: "ثبت محصول جدید",
      editProductPrice: "ویرایش محصول",
      addBenefitsAndDeduction: "ثبت مزایا و کسورات جدید",
      editBenefitsAndDeduction: "ویرایش مزایا و کسورات",
      addFiscalYear: "ثبت سال مالی جدید",
      editFiscalYear: "ویرایش سال مالی",
      atLeastFormula: "برای فرمول تولید موارد بالا را پر کنید",
      addBOM: "ثبت فرمول تولید جدید",
      editBOM: "ویرایش فرمول تولید",
      editWorkCalender: "ویرایش تقویم کاری",
      fiscalYear: "سال مالی",
      editAnnualBase: "ویرایش پایه سنوات",
      addJobTitle: "ثبت عنوان کارآموزی جدید",
      editJobTitle: "ویرایش عنوان کارآموزی",
      addBank: "ثبت حساب بانکی جدید",
      editBank: "ویرایش حساب بانکی",
      addPerson: "ثبت شخص جدید",
      editPerson: "ویرایش شخص",
      addProduction: "ثبت برنامه ی تولید جدید",
      editProduction: "ویرایش برنامه ی تولید",
      addTax: "ثبت مالیات جدید",
      editTax: "ویرایش مالیات",
      addInputOutput: "ثبت تراکنش های سیستمی جدید",
      editInputOutput: "ویرایش تراکنش های سیستمی",
      addStore: "ثبت انبار جدید",
      editStore: "ویرایش انبار",
      inputOutput: "انواع ورود، خروج، دریافت و پرداخت",
      addUnit: "ثبت واحد اندازه گیری جدید",
      editUnit: "ویرایش واحد اندازه‌گیری",
      addReceipt: "ثبت رسید جدید",
      editReceipt: "ویرایش اطلاعات رسید",
      editCommodityDetail: "ویرایش جزئیات کالا",
      atLeastCommodity: "برای ثبت رسید باید حداقل یک ماده اولیه یا بسته بندی ثبت کنید",
      addDraft: "ثبت حواله جدید",
      editDraft: "ویرایش اطلاعات حواله",
      editProductDetail: "ویرایش جزئیات محصول",
      atLeastProduct: "برای ثبت حواله باید حداقل یک کالا ثبت کنید",
      receiptDetail: "نمایش جزئیات رسید",
      draftDetail: "نمایش جزئیات حواله",
      addPackingInfo: "ثبت اطلاعات بسته بندی جدید",
      editPackingInfo: "ویرایش اطلاعات بسته بندی",
      video: "در آپارات روی اشتراک گذاری کلیک کنید و سپس روی کد و کد آی فریم ساخته شده را در اینجا قرار دهید",
      width: "اندازه طول را به درصد وارد کنید تا تصویر در صفحه نمایش های متفاوت درست نمایش داده شود.",
      height: "برای نمایش بهتر عرض یا ارتفاع را به پیکسل (همراه با px) وارد کتید.",
      autoWidthHeight: "در صورت وارد نکردن طول و عرض، طول 100% و عرض یا ارتفاع نیز auto در نظر گرفته خواهد شد.",
      imageAlign: "محل قرارگیری تصویر در متن",
    },
  };
}

export default label;
