export default {

  setJobCategories(state, data) {
    state.jobCategories = data;
  },

  setJobCategoryOptions(state, data) {
    state.jobCategoryOptions = data;
  },

  setBlogCategoryOptions(state, data) {
    state.blogCategoryOptions = data;
  },

  setPackages(state, data) {
    state.packages = data;
  },

  setPackage(state, data) {
    state.package = data;
  },

  setBanners(state, data) {
    state.banners = data;
  },

  setAds(state, data) {
    state.ads = data;
  },

  setDashboard(state, data) {
    state.dashboard = data;
  },

  setDashboardChart(state, data) {
    state.dashboardChart = data;
  },

  setPageContents(state, data) {
    state.pageContents = data;
  },

  setPageContent(state, data) {
    state.pageContent = data;
  },

  setFaqList(state, data) {
    state.faqList = data;
  },

  setTeamList(state, data) {
    state.teamList = data;
  },

  setCompanies(state, data) {
    state.companies = data;
  },

  setCountries(state, data) {
    state.countries = data;
  },

  setCountry(state, data) {
    state.country = data;
  },

};
