export default {

  jobs: (state) => {
    return state.jobs;
  },

  employerJobs: (state) => {
    return state.employerJobs;
  },

  blogs: (state) => {
    return state.blogs;
  },

  blog: (state) => {
    return state.blog;
  },

  events: (state) => {
    return state.events;
  },

  event: (state) => {
    return state.event;
  },

  quizList: (state) => {
    return state.quizList;
  },

  quizDetails: (state) => {
    return state.quizDetails;
  },

  quizExcel: (state) => {
    return state.quizExcel;
  },

};
