import Vue from "vue";
import Vuex from "vuex";

import actions from "./actions";
import state from "./state";
import mutations from "./mutations";
import getters from "./getters";

// Admin vuex Modules
import userX from "./modules/userX";
import baseX from "./modules/baseX";
import operationX from "./modules/operationX";
import reportX from "./modules/reportX"

Vue.use(Vuex);

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules: {
    userX,
    baseX,
    operationX,
    reportX
  },
});
