export default {

  users: (state) => {
    return state.users;
  },
  user: (state) => {
    return state.user;
  },

  employers: (state) => {
    return state.employers;
  },

  interns: (state) => {
    return state.interns;
  },

  roleOptions() {
    return [
      {value: "admin", text: "مدیر سامانه"},
      {value: "member", text: "کارمند"},
    ];
  },

};
