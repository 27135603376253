export default {

  payments: (state) => {
    return state.payments;
  },

  paymentDetails: (state) => {
    return state.paymentDetails;
  },

  contactMessages: (state) => {
    return state.contactMessages;
  },

  messages: (state) => {
    return state.messages;
  },

  jobRequests: (state) => {
    return state.jobRequests;
  },

  paymentStatusOptions() {
    return [
      {value: "PENDING", text: "در انتظار پرداخت"},
      {value: "DONE", text: "پرداخت شده"},
    ];
  },

  paymentTypeOptions() {
    return [
      {value: "package", text: "بسته های اشتراکی"},
      {value: "event", text: "رویدادها"},
    ];
  },


};
