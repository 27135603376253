import axios from "axios";
import alert from "@/lang/AlertMessages.js";
import label from "@/lang/StaticLabels.js";

export default {
  // Job Category ------------------------------------------------------------------
  getJobCategories({ dispatch, commit }) {
    axios
      .get("get-category")
      .then((res) => {
        commit("setJobCategories", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  getJobCategoryOptions({ dispatch, commit }) {
    axios
      .get("get-job-category-titles")
      .then((res) => {
        commit("setJobCategoryOptions", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  getBlogCategoryOptions({ dispatch, commit }) {
    axios
      .get("get-blog-category-titles")
      .then((res) => {
        commit("setBlogCategoryOptions", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  addJobCategory({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("create-category", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  editJobCategory({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .put("update-category/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.edit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  deleteJobCategory({ dispatch }, data) {
    axios
      .delete("delete-category/" + data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.delete,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  // Packages Actions ------------------------------------------------------------------
  getPackages({ dispatch, commit }) {
    axios
      .get("packages/get-all-package")
      .then((res) => {
        commit("setPackages", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  getPackage({ dispatch, commit }, data) {
    axios
      .get("packages/get-package/" + data)
      .then((res) => {
        commit("setPackage", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  addPackage({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("packages/create-package", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  editPackage({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .put("packages/update-package/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.edit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  deletePackage({ dispatch }, data) {
    axios
      .delete("packages/delete-package/" + data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.delete,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  // ADS Actions ------------------------------------------------------------------
  getAds({ dispatch, commit }) {
    axios
      .get("ads/get-all-ads")
      .then((res) => {
        commit("setAds", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  addAds({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("ads/create-ads", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  editAds({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .put("ads/update-ads/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.edit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  deleteAds({ dispatch }, data) {
    axios
      .delete("ads/" + data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.delete,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  // Banners Actions ------------------------------------------------------------------
  getBanners({ dispatch, commit }) {
    axios
      .get("banners/get-all-banners")
      .then((res) => {
        commit("setBanners", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  addBanner({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("banners/create-banner", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  editBanner({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .put("banners/update-banner/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.edit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  deleteBanner({ dispatch }, data) {
    axios
      .delete("banners/delete-banner/" + data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.delete,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  // Dashboard Action -----------------------------------------------------------
  getDashboard({ dispatch, commit }) {
    commit(
      "setLoading",
      { status: 1, message: label.caption.loading },
      { root: true }
    );
    axios
      .get("get-count-all-data")
      .then((res) => {
        commit("setDashboard", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },

    getDashboardChart({ dispatch, commit }) {
        axios
            .get("get-admin-chart-data")
            .then((res) => {
                commit("setDashboardChart", res.data.chartData);
            })
            .catch((error) => {
                dispatch("errorGETHandler", error, { root: true });
            });
    },


  // Page Content Actions ------------------------------------------------------------------
  getPageContents({ dispatch, commit }) {
    axios
      .get("get-all-contents")
      .then((res) => {
        commit("setPageContents", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  getPageContent({ dispatch, commit }, data) {
    axios
      .get("get-content/" + data)
      .then((res) => {
        commit("setPageContent", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  addPageContent({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("create-content", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submit,
            redirect: "PageContentList",
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  editPageContent({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .put("update-content/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.edit,
            redirect: "PageContentList",
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  deletePageContent({ dispatch }, data) {
    axios
      .delete("delete-content/" + data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.delete,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

// FAQ Actions ------------------------------------------------------------------
    getFAQList({ dispatch, commit }) {
        axios
            .get("get-all-faq")
            .then((res) => {
                commit("setFaqList", res.data);
            })
            .catch((error) => {
                dispatch("errorGETHandler", error, { root: true });
            });
    },

    addFAQ({ dispatch, commit }, data) {
        commit("setLoadingBtn", true, { root: true });
        axios
            .post("create-faq", data)
            .then((res) => {
                dispatch(
                    "successPOSTHandler",
                    {
                        server: res,
                        message: alert.success.submit,
                        redirect: false,
                    },
                    { root: true }
                );
            })
            .catch((error) => {
                dispatch("errorPOSTHandler", error, { root: true });
            })
            .finally(() => {
                setTimeout(() => {
                    commit("setLoadingBtn", false, { root: true });
                }, 1000);
            });
    },

    editFAQ({ dispatch, commit }, data) {
        commit("setLoadingBtn", true, { root: true });
        axios
            .put("update-faq-by-id/" + data.id, data.value)
            .then((res) => {
                dispatch(
                    "successPOSTHandler",
                    {
                        server: res,
                        message: alert.success.edit,
                        redirect: false,
                    },
                    { root: true }
                );
            })
            .catch((error) => {
                dispatch("errorPOSTHandler", error, { root: true });
            })
            .finally(() => {
                setTimeout(() => {
                    commit("setLoadingBtn", false, { root: true });
                }, 1000);
            });
    },

    deleteFAQ({ dispatch }, data) {
        axios
            .delete("delete-faq-by-id/" + data)
            .then((res) => {
                dispatch(
                    "successPOSTHandler",
                    {
                        server: res,
                        message: alert.success.delete,
                        redirect: false,
                    },
                    { root: true }
                );
            })
            .catch((error) => {
                dispatch("errorPOSTHandler", error, { root: true });
            });
    },

// Team Actions ------------------------------------------------------------------
    getTeamList({ dispatch, commit }) {
        axios
            .get("team/get-all-team")
            .then((res) => {
                commit("setTeamList", res.data);
            })
            .catch((error) => {
                dispatch("errorGETHandler", error, { root: true });
            });
    },

    addTeam({ dispatch, commit }, data) {
        commit("setLoadingBtn", true, { root: true });
        axios
            .post("team/create-team", data)
            .then((res) => {
                dispatch(
                    "successPOSTHandler",
                    {
                        server: res,
                        message: alert.success.submit,
                        redirect: false,
                    },
                    { root: true }
                );
            })
            .catch((error) => {
                dispatch("errorPOSTHandler", error, { root: true });
            })
            .finally(() => {
                setTimeout(() => {
                    commit("setLoadingBtn", false, { root: true });
                }, 1000);
            });
    },

    editTeam({ dispatch, commit }, data) {
        commit("setLoadingBtn", true, { root: true });
        axios
            .put("team/update-team/" + data.id, data.value)
            .then((res) => {
                dispatch(
                    "successPOSTHandler",
                    {
                        server: res,
                        message: alert.success.edit,
                        redirect: false,
                    },
                    { root: true }
                );
            })
            .catch((error) => {
                dispatch("errorPOSTHandler", error, { root: true });
            })
            .finally(() => {
                setTimeout(() => {
                    commit("setLoadingBtn", false, { root: true });
                }, 1000);
            });
    },

    deleteTeam({ dispatch }, data) {
        axios
            .delete("team/delete-team/" + data)
            .then((res) => {
                dispatch(
                    "successPOSTHandler",
                    {
                        server: res,
                        message: alert.success.delete,
                        redirect: false,
                    },
                    { root: true }
                );
            })
            .catch((error) => {
                dispatch("errorPOSTHandler", error, { root: true });
            });
    },

// Companies Actions ------------------------------------------------------------------
    getCompanies({ dispatch, commit }) {
        axios
            .get("company/get-all-company")
            .then((res) => {
                commit("setCompanies", res.data);
            })
            .catch((error) => {
                dispatch("errorGETHandler", error, { root: true });
            });
    },

    addCompany({ dispatch, commit }, data) {
        commit("setLoadingBtn", true, { root: true });
        axios
            .post("company/create-company", data)
            .then((res) => {
                dispatch(
                    "successPOSTHandler",
                    {
                        server: res,
                        message: alert.success.submit,
                        redirect: false,
                    },
                    { root: true }
                );
            })
            .catch((error) => {
                dispatch("errorPOSTHandler", error, { root: true });
            })
            .finally(() => {
                setTimeout(() => {
                    commit("setLoadingBtn", false, { root: true });
                }, 1000);
            });
    },

    editCompany({ dispatch, commit }, data) {
        commit("setLoadingBtn", true, { root: true });
        axios
            .put("company/update-company/" + data.id, data.value)
            .then((res) => {
                dispatch(
                    "successPOSTHandler",
                    {
                        server: res,
                        message: alert.success.edit,
                        redirect: false,
                    },
                    { root: true }
                );
            })
            .catch((error) => {
                dispatch("errorPOSTHandler", error, { root: true });
            })
            .finally(() => {
                setTimeout(() => {
                    commit("setLoadingBtn", false, { root: true });
                }, 1000);
            });
    },

    deleteCompany({ dispatch }, data) {
        axios
            .delete("company/delete-company/" + data)
            .then((res) => {
                dispatch(
                    "successPOSTHandler",
                    {
                        server: res,
                        message: alert.success.delete,
                        redirect: false,
                    },
                    { root: true }
                );
            })
            .catch((error) => {
                dispatch("errorPOSTHandler", error, { root: true });
            });
    },

// Country Actions ------------------------------------------------------------------
    getCountries({ dispatch, commit }) {
        axios
            .get("get-all-country")
            .then((res) => {
                commit("setCountries", res.data);
            })
            .catch((error) => {
                dispatch("errorGETHandler", error, { root: true });
            });
    },

    getCountry({ dispatch, commit }, data) {
        axios
            .get("get-country-by-id/" + data)
            .then((res) => {
                commit("setCountry", res.data);
            })
            .catch((error) => {
                dispatch("errorGETHandler", error, { root: true });
            });
    },

    addCountry({ dispatch, commit }, data) {
        commit("setLoadingBtn", true, { root: true });
        axios
            .post("create-country", data)
            .then((res) => {
                dispatch(
                    "successPOSTHandler",
                    {
                        server: res,
                        message: alert.success.submit,
                        redirect: false,
                    },
                    { root: true }
                );
            })
            .catch((error) => {
                dispatch("errorPOSTHandler", error, { root: true });
            })
            .finally(() => {
                setTimeout(() => {
                    commit("setLoadingBtn", false, { root: true });
                }, 1000);
            });
    },

    editCountry({ dispatch, commit }, data) {
        commit("setLoadingBtn", true, { root: true });
        axios
            .put("update-country-by-id/" + data.id, data.value)
            .then((res) => {
                dispatch(
                    "successPOSTHandler",
                    {
                        server: res,
                        message: alert.success.edit,
                        redirect: false,
                    },
                    { root: true }
                );
            })
            .catch((error) => {
                dispatch("errorPOSTHandler", error, { root: true });
            })
            .finally(() => {
                setTimeout(() => {
                    commit("setLoadingBtn", false, { root: true });
                }, 1000);
            });
    },

    deleteCountry({ dispatch }, data) {
        axios
            .delete("delete-country-by-id/" + data)
            .then((res) => {
                dispatch(
                    "successPOSTHandler",
                    {
                        server: res,
                        message: alert.success.delete,
                        redirect: false,
                    },
                    { root: true }
                );
            })
            .catch((error) => {
                dispatch("errorPOSTHandler", error, { root: true });
            });
    },

};
