export default {

  jobCategories: [],
  jobCategoryOptions: [],
  blogCategoryOptions: [],
  packages: [],
  package: {},
  banners: [],
  ads: [],
  dashboard: {},
  dashboardChart: [],
  pageContents: [],
  pageContent: {},
  faqList: [],
  teamList: [],
  companies: [],
  countries: [],
  country: {},

};
