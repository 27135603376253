import axios from "axios";
import label from "@/lang/StaticLabels";
import alert from "@/lang/AlertMessages";

export default {

  getPayments({ dispatch, commit }, data) {
    commit(
        "setLoading",
        { status: 1, message: label.caption.loading },
        { root: true }
    );
    axios
        .get("payment/get-all-payments?" + data)
        .then((res) => {
          commit("setPayments", res.data);
        })
        .catch((error) => {
          dispatch("errorGETHandler", error, { root: true });
        })
        .finally(() => {
          setTimeout(() => {
            commit("setLoading", { status: 0, message: "" }, { root: true });
          }, 500);
        });
  },

  getPaymentDetails({ dispatch, commit }, data) {
    axios
        .get("payment/get-payments-by-id/" + data)
        .then((res) => {
          commit("setPaymentDetails", res.data);
        })
        .catch((error) => {
          dispatch("errorGETHandler", error, { root: true });
        });
  },

  getMessages({ dispatch, commit }) {
    axios
        .get("payment/get-payments-by-id")
        .then((res) => {
          commit("setMessages", res.data);
        })
        .catch((error) => {
          dispatch("errorGETHandler", error, { root: true });
        });
  },

  getJobRequests({ dispatch, commit }) {
    axios
        .get("payment/get-payments-by-id")
        .then((res) => {
          commit("setJobRequests", res.data);
        })
        .catch((error) => {
          dispatch("errorGETHandler", error, { root: true });
        });
  },

  getContactMessages({ dispatch, commit }) {
      axios
          .get("get-all-contact")
          .then((res) => {
              commit("setContactMessages", res.data);
          })
          .catch((error) => {
              dispatch("errorGETHandler", error, { root: true });
          });
  },

  deleteContactMessage({ dispatch }, data) {
      axios
          .delete("delete-contact-by-id/" + data)
          .then((res) => {
              dispatch(
                  "successPOSTHandler",
                  {
                      server: res,
                      message: alert.success.delete,
                      redirect: false,
                  },
                  { root: true }
              );
          })
          .catch((error) => {
              dispatch("errorPOSTHandler", error, { root: true });
          });
  },


};
