import axios from "axios";
import alert from "@/lang/AlertMessages.js";
import label from "@/lang/StaticLabels.js";

export default {
  // Users Actions ------------------------------------------------------------------
  getUsers({ dispatch, commit }) {
    commit(
      "setLoading",
      { status: 1, message: label.caption.loading },
      { root: true }
    );
    axios
      .get("get-all-personnel")
      .then((res) => {
        commit("setUsers", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },

  getUser({ dispatch, commit }, data) {
    axios
      .get("get-personnel/" + data)
      .then((res) => {
        commit("setUser", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  addUser({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("create-personnel", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.addUser,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  editUser({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .put("update-personnel/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.editUser,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  deleteUser({ dispatch }, data) {
    axios
      .delete("delete-personnel/" + data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.deleteUser,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },


  // Employers Actions ------------------------------------------------------------------
  getEmployers({ dispatch, commit }) {
    commit(
      "setLoading",
      { status: 1, message: label.caption.loading },
      { root: true }
    );
    axios
      .get("users/employers/get-employers")
      .then((res) => {
        commit("setEmployers", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },

  addEmployer({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("add-employer", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.addUser,
            redirect: "EmployersList",
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  addEmployerLogo({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("employer-add-company-logo/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.upload,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  addEmployerBanner({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("employer-add-baner-image/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.upload,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  editEmployer({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .patch("users/employers/update-employers/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.editUser,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  activateEmployer({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .patch("users/employers/" + data + "/activate")
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.changeStatus,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  deactivateEmployer({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .patch("users/employers/" + data + "/deactivate")
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.changeStatus,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  deleteEmployer({ dispatch }, data) {
    axios
      .delete("users/employers/delete-employers/" + data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.deleteUser,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },


  // Intern Actions ------------------------------------------------------------------
  getInterns({ dispatch, commit }) {
    commit(
      "setLoading",
      { status: 1, message: label.caption.loading },
      { root: true }
    );
    axios
      .get("users/interns/get-interns")
      .then((res) => {
        commit("setInterns", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },

  addIntern({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("add-intern", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.addUser,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  addInternLogo({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("intern-add-profile-picture-by-id/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.upload,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  editIntern({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .patch("users/interns/update-interns/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.editUser,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  activateIntern({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .patch("users/interns/" + data + "/activate")
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.changeStatus,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  deactivateIntern({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .patch("users/interns/" + data + "/deactivate")
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.changeStatus,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  deleteIntern({ dispatch }, data) {
    axios
      .delete("users/interns/delete-interns/" + data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.deleteUser,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },


};
