<template>
  <div class="header">
    <v-navigation-drawer v-model="drawer" app right width="300" class="sidebar">
      <div class="sidebar__avatar-wrapper">
        <div class="top-logo">
          <img src="@/assets/images/logo-1.png" contain />
        </div>
        <div class="pt-0">
          <h2 class="text-p-second">شـبـکــــه اسـتخـــدام و کـارآمـــوزی</h2>
          <h4 class="text-p-second">S H A B A K E H</h4>
        </div>
      </div>

      <v-list>
        <template v-for="(nav, navIndex) in navItems">
          <v-list-item
            v-if="nav.to"
            :key="`${navIndex}-a`"
            :to="{ name: nav.to }"
            link
            dark
          >
            <v-list-item-icon>
              <span class="icon-box">
                <v-icon>{{ nav.icon }}</v-icon>
              </span>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ nav.label }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group
            v-if="nav.subItems"
            :key="`${navIndex}-b`"
            :prepend-icon="`${nav.icon} fa-em`"
            v-model="nav.active"
            append-icon="fas fa-chevron-down fa-sm"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  {{ nav.label }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <span v-for="(sub, subIndex) in nav.subItems" :key="subIndex" class="sub-item-menu">
              <v-list-item :to="{ name: sub.to }" link dark >
                <v-list-item-icon>
                  <v-icon small>{{ sub.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ sub.label }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

            </span>
          </v-list-group>
        </template>
      </v-list>

      <span class="sidebar-bottom-caption">
        <a href="https://persianfrontend.com/" target="_blank">
          Design and Developed by Persian Frontend
        </a>
      </span>
    </v-navigation-drawer>

    <v-app-bar flat color="white" app>
      <v-toolbar-title>
        <div class="d-flex header-logo" v-if="!drawer">
          <img src="@/assets/images/logo-1.png" contain />
          <div class="ms-1">
            <h4 class="text-p-second">
              پنل مدیریت وب اپلیکیشن شــبـکه
            </h4>
            <h4 class="text-p-second">
              SHABAKEH APP ADMIN PANEL
            </h4>
          </div>
        </div>
      </v-toolbar-title>
      <v-btn
        @click="toggleSidebar()"
        class="drawer-hide"
        elevation="0"
        fab
        v-if="drawer"
        ><i class="fas fa-chevron-right"></i>
      </v-btn>
      <v-btn
        @click="toggleSidebar()"
        class="drawer-show"
        elevation="0"
        fab
        v-else
        ><i class="fas fa-bars"></i>
      </v-btn>

      <v-spacer></v-spacer>

      <NotifyDropDown />

      <AccountDropDown />
    </v-app-bar>
  </div>
</template>

<script>
import navItems from "@/containers/navbar.js";
import AccountDropDown from "./dropDown/Account";
import NotifyDropDown from "./dropDown/Notify";

export default {
  name: "TheHeader",
  components: {
    AccountDropDown,
    NotifyDropDown,
  },
  data() {
    return {
      drawer: null,
      navItems,
    };
  },
  methods: {
    toggleSidebar() {
      this.drawer = !this.drawer;
    },
  },
};
</script>
