export default {

  setPayments(state, data) {
    state.payments = data;
  },

  setPaymentDetails(state, data) {
    state.paymentDetails = data;
  },

  setContactMessages(state, data) {
    state.contactMessages = data;
  },

  setMessages(state, data) {
    state.messages = data;
  },

  setJobRequests(state, data) {
    state.jobRequests = data;
  },



};
