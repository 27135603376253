import axios from "axios";
import alert from "@/lang/AlertMessages.js";

export default {

  // Jobs Action ------------------------------------------------------------------
  getJobs({ dispatch, commit }) {
    axios
      .get("get-jobs-by-filter")
      .then((res) => {
        commit("setJobs", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  getEmployerJobs({ dispatch, commit }, data) {
    axios
      .get("get-employer-jobs-by-id/" + data)
      .then((res) => {
        commit("setEmployerJobs", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  editJob({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .put("edit-job-by-id/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.edit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  deleteJob({ dispatch }, data) {
    axios
      .delete("delete-job-by-id/" + data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.delete,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  // Blog actions ------------------------------------------------------------------
  getBlogs({ dispatch, commit }) {
    axios
      .get("blogs/get-all-blogs")
      .then((res) => {
        commit("setBlogs", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  getBlog({ dispatch, commit }, data) {
    axios
      .get("blogs/get-blog/" + data)
      .then((res) => {
        commit("setBlog", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  addBlog({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("blogs/create-blogs", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submit,
            redirect: "BlogList",
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  editBlog({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .put("blogs/update-blogs/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.edit,
            redirect: "BlogList",
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  deleteBlog({ dispatch }, data) {
    axios
      .delete("blogs/delete-blogs/" + data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.delete,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  // Event actions ------------------------------------------------------------------
  getEvents({ dispatch, commit }) {
    axios
      .get("events/get-all-events")
      .then((res) => {
        commit("setEvents", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  getEvent({ dispatch, commit }, data) {
    axios
      .get("events/get-event/" + data)
      .then((res) => {
        commit("setEvent", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  addEvent({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("events/create-event", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  editEvent({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .put("events/update-event/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.edit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  deleteEvent({ dispatch }, data) {
    axios
      .delete("events/delete-events/" + data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.delete,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },


  // QUIZ actions ------------------------------------------------------------------
  getQuizList({ dispatch, commit }) {
    axios
      .get("quiz/get-all-quizzes")
      .then((res) => {
        commit("setQuizList", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  getQuizDetails({ dispatch, commit }, data) {
    axios
      .get("quiz/admin-get-quizzes/" + data)
      .then((res) => {
        commit("setQuizDetails", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  getQuizExcel({ dispatch, commit }) {
      axios
          .get("quiz/get-example-quiz-file", {responseType : 'blob'})
            .then((res) => {
                commit("setQuizExcel", res.data);
            })
            .catch((error) => {
                dispatch("errorGETHandler", error, { root: true });
            });
  },

  uploadQuizWithExcel({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("quiz/upload-quiz-exel-file", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.upload,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

    updateQuiz({ dispatch, commit }, data) {
        commit("setLoadingBtn", true, { root: true });
        axios
            .put("quiz/update-quiz/" + data.id, data.value)
            .then((res) => {
                dispatch(
                    "successPOSTHandler",
                    {
                        server: res,
                        message: alert.success.upload,
                        redirect: false,
                    },
                    { root: true }
                );
            })
            .catch((error) => {
                dispatch("errorPOSTHandler", error, { root: true });
            })
            .finally(() => {
                setTimeout(() => {
                    commit("setLoadingBtn", false, { root: true });
                }, 1000);
            });
    },

    deleteQuiz({ dispatch }, data) {
        axios
            .delete("delete-quiz/" + data)
            .then((res) => {
                dispatch(
                    "successPOSTHandler",
                    {
                        server: res,
                        message: alert.success.delete,
                        redirect: false,
                    },
                    { root: true }
                );
            })
            .catch((error) => {
                dispatch("errorPOSTHandler", error, { root: true });
            });
    },





};
