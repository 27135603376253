export default {

  setJobs(state, data) {
    state.jobs = data;
  },

  setEmployerJobs(state, data) {
    state.employerJobs = data;
  },

  setBlogs(state, data) {
    state.blogs = data;
  },

  setBlog(state, data) {
    state.blog = data;
  },

  setEvents(state, data) {
    state.events = data;
  },

  setEvent(state, data) {
    state.event = data;
  },

  setQuizList(state, data) {
    state.quizList = data;
  },

  setQuizDetails(state, data) {
    state.quizDetails = data;
  },

  setQuizExcel(state, data) {
    state.quizExcel = data;
  },

};
