import Vue from "vue";
import VueRouter from "vue-router";
import GlobalGuards from "@/guards/Global";
import label from "@/lang/StaticLabels.js";

import TheContainer from "@/containers/TheContainer";
const Page404 = () => import("@/views/errors/Page404");
const Page403 = () => import("@/views/errors/Page403");
const Page500 = () => import("@/views/errors/Page500");
const PageUnderConstruction = () =>
  import("@/views/errors/PageUnderConstruction");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Container",
    component: TheContainer,
    redirect: "/dashboard",
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        meta: {
          label: label.page.dashboard,
          requiresAuth: true,
        },
        component: () => import("@/views/pages/Dashboard"),
      },
      {
        path: "profile",
        name: "Profile",
        meta: {
          label: label.page.profile,
          requiresAuth: true,
        },
        component: () => import("@/views/auth/Profile"),
      },

      // Persons and Users Routing System ---------------------------------------------------------
      {
        path: "users",
        name: "Users",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        redirect: "/administrators/list",
        children: [
          {
            path: "administrators",
            name: "Administrators",
            meta: {
              label: "مدیران سامانه",
              requiresAuth: true,
            },
            component: () => import("@/views/pages/users/administrators/index"),
          },

        ],
      },
      {
        path: "employers",
        name: "Employers",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        redirect: "/employers/list",
        children: [
          {
            path: "list",
            name: "EmployersList",
            meta: {
              label: "کارفرمایان",
              requiresAuth: true,
            },
            component: () => import("@/views/pages/users/employers/index"),
          },
          {
            path: "add",
            name: "AddEmployer",
            meta: {
              label: "معرفی کارفرما",
              requiresAuth: true,
            },
            component: () => import("@/views/pages/users/employers/Add"),
          },
          {
            path: "edit/:id",
            name: "EditEmployer",
            meta: {
              label: "ویرایش اطلاعات کارفرما",
              requiresAuth: true,
            },
            component: () => import("@/views/pages/users/employers/Edit"),
          },
        ],
      },
      {
        path: "apprentices",
        name: "Apprentices",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        redirect: "/apprentices/list",
        children: [
          {
            path: "list",
            name: "ApprenticesList",
            meta: {
              label: "کارآموزان",
              requiresAuth: true,
            },
            component: () => import("@/views/pages/users/apprentices/index"),
          },
          {
            path: "add",
            name: "AddApprentices",
            meta: {
              label: "ثبت کارآموز جدید",
              requiresAuth: true,
            },
            component: () => import("@/views/pages/users/apprentices/Add"),
          },
          {
            path: "edit/:id",
            name: "EditApprentices",
            meta: {
              label: "ویرایش کارآموز",
              requiresAuth: true,
            },
            component: () => import("@/views/pages/users/apprentices/Edit"),
          },
        ],
      },

      // Base Informations - اطلاعات پایه---------------------------------------------------------
      {
        path: "base",
        name: "Base",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        redirect: "/base/list",
        children: [
          {
            path: "jobsCategory/list",
            name: "JobsCategory",
            meta: {
              label: "دسته بندی ها",
              requiresAuth: true,
            },
            component: () => import("@/views/pages/base/jobsCategory/index"),
          },
          {
            path: "packages",
            name: "Packages",
            meta: {
              label: "بسته های اشتراکی",
              requiresAuth: true,
            },
            component: () => import("@/views/pages/base/packages/index"),
          },
          {
            path: "advertising",
            name: "Advertising",
            meta: {
              label: "تبلیغات",
              requiresAuth: true,
            },
            component: () => import("@/views/pages/base/advertising/index"),
          },
          {
            path: "banners",
            name: "Banners",
            meta: {
              label: "بنرها",
              requiresAuth: true,
            },
            component: () => import("@/views/pages/base/banner/index"),
          },

          {
            path: "content",
            name: "PageContent",
            component: {
              render(c) {
                return c("router-view");
              },
            },
            redirect: "/base/content/list",
            children: [
              {
                path: "list",
                name: "PageContentList",
                meta: {
                  label: "مدیریت صفحات",
                  requiresAuth: true,
                },
                component: () =>
                  import("@/views/pages/base/content/index"),
              },
              {
                path: "add",
                name: "AddPageContent",
                meta: {
                  label: "ثبت محتوا صفحه",
                  requiresAuth: true,
                },
                component: () =>
                  import("@/views/pages/base/content/Add"),
              },
              {
                path: "edit/:id",
                name: "EditPageContent",
                meta: {
                  label: "ویرایش محتوا صفحه",
                  requiresAuth: true,
                },
                component: () =>
                  import("@/views/pages/base/content/Edit"),
              },
            ]
          },

          {
            path: "FAQ",
            name: "FAQManagement",
            meta: {
              label: "سوالات متداول",
              requiresAuth: true,
            },
            component: () => import("@/views/pages/base/faq/index"),
          },

          {
            path: "companies",
            name: "Company",
            meta: {
              label: "شرکت ها",
              requiresAuth: true,
            },
            component: () => import("@/views/pages/base/company/index"),
          },

          {
            path: "Team",
            name: "Team",
            meta: {
              label: "تیم ما",
              requiresAuth: true,
            },
            component: () => import("@/views/pages/base/team/index"),
          },
          {
            path: "countries",
            name: "Country",
            meta: {
              label: "کشورها",
              requiresAuth: true,
            },
            component: () => import("@/views/pages/base/country/index"),
          },
        ],
      },


      // Operation - عملیات -------------------------------------------------------------------------------------------
      {
        path: "operation",
        name: "Operation",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        redirect: "/operation/jobs/list",
        children: [
          {
            path: "jobs",
            name: "Jobs",
            meta: {
              label: "فرصت های کارآموزی و آگهی ها",
              requiresAuth: true,
            },
            component: () =>
              import("@/views/pages/operation/jobs/index"),
          },

          {
            path: "blog",
            name: "Blog",
            component: {
              render(c) {
                return c("router-view");
              },
            },
            redirect: "/operation/blog/list",
            children: [
              {
                path: "list",
                name: "BlogList",
                meta: {
                  label: "بلاگ",
                  requiresAuth: true,
                },
                component: () =>
                  import("@/views/pages/operation/blog/index"),
              },
              {
                path: "add",
                name: "AddBlog",
                meta: {
                  label: "ثبت مقاله جدید",
                  requiresAuth: true,
                },
                component: () =>
                  import("@/views/pages/operation/blog/Add"),
              },
              {
                path: "edit/:id",
                name: "EditBlog",
                meta: {
                  label: "ویرایش مقاله",
                  requiresAuth: true,
                },
                component: () =>
                  import("@/views/pages/operation/blog/Edit"),
              },
            ]
          },

          {
            path: "eventManagement",
            name: "EventManagement",
            meta: {
              label: "مدیریت رویدادها",
              requiresAuth: true,
            },
            component: () =>
              import("@/views/pages/operation/eventManagement/index"),
          },
          {
            path: "exam",
            name: "Exam",
            meta: {
              label: "تست های کارآموزی",
              requiresAuth: true,
            },
            component: () => import("@/views/pages/operation/exam/index"),
          },
        ],
      },

      // Report -  گزارشات ------------------------------------------------------------------------------------
      {
        path: "reports",
        name: "Report",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        redirect: "/reports/jobRequests",
        children: [
          {
            path: "transactions",
            name: "Transactions",
            meta: {
              label: "تراکنش های مالی و پرداخت ها",
              requiresAuth: true,
            },
            component: () => import("@/views/pages/report/Transactions"),
          },
          {
            path: "contact",
            name: "ContactMessages",
            meta: {
              label: "تماس با ما",
              requiresAuth: true,
            },
            component: () => import("@/views/pages/report/Contact"),
          },
        ],
      },

      // end of Container
    ],
  },

  // other Pages - without container
  {
    path: "/login",
    name: "Login",
    meta: {
      label: label.page.login,
    },
    component: () => import("@/views/auth/Login"),
    beforeEnter(to, from, next) {
      GlobalGuards.login(to, from, next);
    },
  },

  {
    path: "/pages",
    redirect: "/pages/404",
    name: "Pages",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "404",
        name: "Page404",
        component: Page404,
      },
      {
        path: "403",
        name: "Page403",
        component: Page403,
      },
      {
        path: "500",
        name: "Page500",
        component: Page500,
      },
      {
        path: "underConstruction",
        name: "PageUnderConstruction",
        component: PageUnderConstruction,
      },
    ],
  },

  {
    path: "*",
    component: Page404,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: () => ({ y: 0 }),
  routes,
});

router.beforeEach((to, from, next) => GlobalGuards.beforeEach(to, from, next));
router.afterEach((to, from) => GlobalGuards.afterEach(to, from));

export default router;
