<template>
  <v-menu
    bottom
    rounded
    right
    transition="scale-transition"
    nudge-bottom="6"
    max-width="300"
    offset-y
  >
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on">
        <v-badge
          bordered
          top
          right
          color="error"
          dot
          offset-x="28"
          offset-y="23"
        >
          <v-icon>mdi-bell-outline</v-icon>
        </v-badge>
      </v-btn>
    </template>
    <v-list>
    <v-list-item
      class="msg-list-item"
      v-for="(message, index) in messages.slice(0, 5)"
      :key="index"
      dense
    >
      <v-list-item-title>
        <v-icon size="8" color="error" class="me-1">mdi-circle</v-icon>
        {{ message.subject }}
      </v-list-item-title>
    </v-list-item>
    <v-list-item
      class="p-fs-n"
      v-if="messages && messages.length === 0"
    >
      {{ label.caption.noNotify }}
    </v-list-item>
    <v-list-item
      dense
      class="mt-1"
    >
      <v-btn
        text
        small
        block
        to="#"
      >
        <v-icon small class="me-1">mdi-bell-outline</v-icon>
        {{ label.menu.allNotifications }}
      </v-btn>
    </v-list-item>
  </v-list>
  </v-menu>
</template>

<script>
import label from "@/lang/StaticLabels.js";

export default {
  name: "TheNotifyDropDown",
  data() {
    return{
      label,
      messages: [
        {subject: 'بروزرسانی نرم‌افزار نسخه 2.5.1'},
        {subject: '4 سفارش جدید ثبت شده است'},
      ],
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
  },
};
</script>
