export default {

  setUsers(state, data) {
    state.users = data;
  },

  setUser(state, data) {
    state.user = data;
  },

  setEmployers(state, data) {
    state.employers = data;
  },

  setInterns(state, data) {
    state.interns = data;
  },

};
