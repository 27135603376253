import label from "@/lang/StaticLabels.js";

let navItems = [
  {
    to: "Dashboard",
    label: label.menu.dashboard,
    icon: "mdi-view-dashboard-outline",
    active: false,
    subItems: false,
  },
  {
    to: null,
    label: label.menu.baseInformation,
    icon: "mdi-database-outline",
    active: false,
    subItems: [
      {
        to: "JobsCategory",
        label: "دسته بندی ها",
        icon: "mdi-subdirectory-arrow-left",
      },

      {
        to: "Packages",
        label: "بسته های اشتراکی",
        icon: "mdi-subdirectory-arrow-left",
      },
      {
        to: "Advertising",
        label: "تبلیغات ",
        icon: "mdi-subdirectory-arrow-left",
        divider: true,
      },
      {
        to: "Banners",
        label: "مدیریت بنرها",
        icon: "mdi-subdirectory-arrow-left",
        divider: true,
      },
      {
        to: "PageContent",
        label: "مدیریت صفحات",
        icon: "mdi-subdirectory-arrow-left",
        divider: true,
      },
      {
        to: "FAQManagement",
        label: "سوالات متداول",
        icon: "mdi-subdirectory-arrow-left",
        divider: true,
      },
      {
        to: "Company",
        label: "مدیریت شرکت ها",
        icon: "mdi-subdirectory-arrow-left",
        divider: true,
      },
      {
        to: "Team",
        label: "تیم ما و همکاران",
        icon: "mdi-subdirectory-arrow-left",
        divider: true,
      },
      {
        to: "Country",
        label: "کشورها",
        icon: "mdi-subdirectory-arrow-left",
        divider: true,
      },
    ],
  },
  {
    to: null,
    label: label.menu.opertaion,
    icon: "mdi-cog-outline",
    active: false,
    subItems: [
      {
        to: "Jobs",
        label: "فرصت های کارآموزی و آگهی ها",
        icon: "mdi-subdirectory-arrow-left",
      },
      {
        to: "EventManagement",
        label: "مدیریت رویدادها",
        icon: "mdi-subdirectory-arrow-left",
      },
      {
        to: "Blog",
        label: "بلاگ و مقالات",
        icon: "mdi-subdirectory-arrow-left",
      },
      {
        to: "Exam",
        label: "تست های هوش مصنوعی",
        icon: "mdi-subdirectory-arrow-left",
      },
    ],
  },
  {
    to: null,
    label: "مدیریت کاربران",
    icon: "mdi-account-group-outline",
    active: false,
    subItems: [
      {
        to: "Administrators",
        label: "مدیران سامانه",
        icon: "mdi-subdirectory-arrow-left",
      },
      {
        to: "EmployersList",
        label: "کارفرمایان",
        icon: "mdi-subdirectory-arrow-left",
      },
      {
        to: "ApprenticesList",
        label: "کارآموزان",
        icon: "mdi-subdirectory-arrow-left",
      },
    ],
  },
  {
    to: null,
    label: label.menu.reports,
    icon: "mdi-chart-bar",
    active: false,
    subItems: [
      {
        to: "Transactions",
        label: "تراکنش و پرداخت ها",
        icon: "mdi-subdirectory-arrow-left",
      },
      {
        to: "ContactMessages",
        label: "فرم تماس با ما",
        icon: "mdi-subdirectory-arrow-left",
      },
    ],
  },

];

export default navItems;
